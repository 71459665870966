import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ImageContent from '../components/ImageContent';
import FeaturedSystems from '../components/FeaturedSystems';
import ModularBlocks from '../components/ModularBlocks';

const HomePageTemplate = ({
  data: { datoCmsHome },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    subtitle,
    bannerOverline,
    bannerLinkPage,
    bannerLinkText,
    bannerImage,
    introductionImage,
    introductionOverline,
    introductionHeading,
    introductionContent,
    featuredSystemsOverline,
    featuredSystemsHeading,
    modularBlocks,
  } = datoCmsHome;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsHome}
    >
      <main>
        <Banner
          overline={bannerOverline}
          heading={title}
          text={subtitle}
          link={{
            page: bannerLinkPage,
            text: bannerLinkText,
          }}
          image={bannerImage}
          locale={locale}
          displayWave={true}
          isHomepage={true}
        />
        <ImageContent
          image={introductionImage}
          overline={introductionOverline}
          heading={introductionHeading}
          content={introductionContent}
          locale={locale}
          version={1}
          altColours={true}
          isHomepage={true}
        />
        <FeaturedSystems
          overline={featuredSystemsOverline}
          heading={featuredSystemsHeading}
          locale={locale}
          altColours={true}
        />
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
    </Layout>
  );
};

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery($id: String!) {
    datoCmsHome(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      bannerOverline
      bannerLinkPage {
        ...LinkFragment
      }
      bannerLinkText
      bannerImage {
        ...HomepageBannerImageFragment
      }
      introductionImage {
        fluid(maxHeight: 540, maxWidth: 540) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      introductionOverline
      introductionHeading
      introductionContent
      featuredSystemsOverline
      featuredSystemsHeading
      modularBlocks {
        ...CallbackCtaModularBlockFragment
        ...FeaturedCaseStudyModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedPointsModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...LatestNewsModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default HomePageTemplate;
